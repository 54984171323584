.widget-select {
  @apply
    py-2 px-3
    flex
    flex-grow
    appearance-none
    outline-none
    bg-white
    text-gray-800
    border
    border-gray-400
    border-opacity-50
    focus-within:border-hr-logo-green
    focus-within:ring-hr-logo-green
    focus-within:ring
    focus-within:ring-opacity-50
    rounded-md
    w-full;
}

.widget-select-input {
  padding: 0;
  @apply 
      flex-grow
      border-none
      focus:outline-none
      focus:border-none
      w-full
      pr-6;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;

  /**
   * If an option is selected, there will be an X icon next to the dropdown
   * arrow, if they are too close together there's a good chance the user will
   * accidentally click/tap the wrong one (users will aim for the drop-down
   * icon even if technically it does nothing).
   */
  &.selected {
    background-position: right 0.2rem center;
  }
  &.not-selected {
    background-position: right center;
  }
}

.widget-select-container .widget-select-clear-button {
    @apply
      overflow-hidden
      w-5
      whitespace-nowrap
      bg-transparent
      hover:bg-transparent
      bg-no-repeat
      bg-center
      flex-shrink;
    text-indent: -9999px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23a0aec0' %3E%3Cpath d='M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z' /%3E%3C/svg%3E");
    min-width: 1.25rem;
}

.widget-select-container {
  @apply
    flex
    flex-shrink
    flex-grow-0
    relative;
}

.widget-select-dropdown,
.widget-select-error {
  @apply
    flex flex-col
    w-full
    absolute
    top-8
    max-h-96
    overflow-y-auto
    border
    border-t-0
    rounded-b-md
    border-hr-logo-green
    z-50;
}

.widget-select-group {
  @apply
    flex flex-col
    ;
}
.widget-select-group-header {
  @apply
    bg-white
    px-2 py-1
    text-lg font-bold
    ;
}

.widget-select-loading,
.widget-select-error {
  @apply
    border
    border-hr-logo-green
    border-t-0
    rounded-b-md
    px-2 py-1
    bg-white;
}

/* This nesting is for specificity, as HR has some global styling that will
   clobber this otherwise. */
.widget-select-container {
  .widget-select-option {
    @apply
      bg-white
      px-2 py-1
      hover:bg-hr-logo-green-dark-1
      hover:text-white;

    &.active {
      @apply
        bg-hr-logo-green-dark-1
        text-white;
    }
  }
  .widget-select-option:first-child {
    /* @todo: First item in the list is too close to the input's text,
      needs some padding on top, looks bad. */
    @apply mt-1;
  }
}

.widget-select-dropdown .loading-spinner {
  @apply
    mt-5
    m-1
    z-50;
  width: 4rem;
}
