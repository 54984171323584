
/**
 * @file
 * Signatures styling for in-form elements.
 *
 * @see modal-signature.css
 */

.signatures-heading {
  @apply
    text-xl
    font-bold;
}

.signature-group {
  @apply
    flex
    flex-wrap
    flex-grow
    gap-5
    p-3;
}

.signature-widget {
  @apply
    relative
    flex-grow
    flex-shrink-0
    max-w-full
    pb-3;
  min-width: 15rem;
  @media (min-width: 640px) {
    min-width: 40%;
  }
  @media (min-width: 768px) {
    max-width: 33.33%;
  }
}

.btn-group-email {
  @apply
    w-full;
}

.signature-type {
  @apply
    text-sm
    font-medium;
}

.signature-copy-link,
.signature-add-email {
  @apply
    text-sm
    text-hr-logo-green-dark-1;
}

.signature-name,
.signature-email {
  @apply
    text-sm;
}

.sign-here {
  @apply
    text-hr-background-dark
    text-opacity-90
    border-dashed
    border-b-2
    border-gray-300
    mb-3
    w-full
    h-32;
  min-height: 5rem;
}
.sign-here.emailed {
  display: flex;
}
.sign-here>img {
    @apply max-h-32;
}

.emailed-notice {
  @apply self-center text-center w-full;
}
