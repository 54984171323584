.sig-request-review-form, .sig-request-resolve-form {
    & .modal-heading {
        font-size: 1.35rem;
        font-weight: 400;
    }
}

.sig-request-review-form {
    display: flex;
    flex-direction: column;

    .image-replace {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        &.no-saved-email {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-6 h-6'%3e%3cpath fill-rule='evenodd' d='M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z' clip-rule='evenodd'/%3e%3c/svg%3e");
        }
        &.email-already-sent {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-6 h-6'%3e%3cpath d='M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z'/%3e%3cpath d='M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z'/%3e%3c/svg%3e");
        }

        &.edit-icon {
            position: relative;
            top: -2px;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(64, 89, 29)' class='w-6 h-6'%3e%3cpath d='M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z'/%3e%3c/svg%3e");
        }
    }
}

.sig-request-group {
    display: flex;
    flex-direction: row;
    @apply mb-6;
}

.sig-request-group-header {
    &>.form-abbr {
        position: sticky;
        top: 1rem;

        @apply
            flex-shrink-0
            w-14
            h-14
            mr-3
        ;
    }
}
.sig-request-group-select {
    display: flex;
    flex-direction: column;
}

.sig-request-subgroup {
    &>summary {
        margin-top: 1rem;
        font-size: 0.9rem;
        text-transform: uppercase;
    }

    &>span {
        display: flex;
        flex-direction: row;
    }
}

.sig-review-check, .sig-request-resolve-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    &>label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0.5rem;
    }
}

.edit-btn {
    display: flex;
    flex-direction: row;
}
.edit-btn-text {
    @apply text-sm text-hr-logo-green-dark-2;
}

.sig-request-table {
    & thead td {
        font-weight: 500;
    }
    & td {
        padding-right: 1rem;
    }
}

.signature-req-edit-email>.modal-heading {
    margin-bottom: -1rem;
}

/*
* This shit is duplicated here solely because the employer container needs to
* go one row lower than it does in the other email editing modal...
*
* I HATE GRID LAYOUTS I HATE GRID LAYOUTS I HATE GRID LAYOUTS I HATE GRID
* LAYOUTS I HATE GRID LAYOUTS I HATE GRID LAYOUTS I HATE GRID LAYOUTS I HATE
* GRID LAYOUTS I HATE GRID LAYOUTS I HATE GRID LAYOUTS I HATE GRID LAYOUTS
*/
.signature-employer-container {
  @apply
    text-sm
    flex flex-col
    col-span-2
    row-start-2
    md:col-start-4
    md:col-span-6
    lg:col-start-5
    lg:col-span-4;
}
.req-email-address {
  @apply
    col-span-2
    row-start-3
    md:col-start-4
    md:col-span-6
    lg:col-start-5
    lg:col-span-4;
}
