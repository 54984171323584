.search-subform {
  @apply
    col-span-12;
}

.search-subform-search {
  @apply
    mb-5;
}

.search-subform-select-wrapper {
  @apply
    mb-3;
}

.student-subform {
  @apply
    col-span-12;
}
