/**
 * Selects cunningly disguised under normal text, reduces clutter.
 */

.hidden-select-edit-button {
  @apply
    text-xs
    mr-2
    text-hr-logo-green-dark-2;
}
