.pager {
  @apply
    mt-10
    justify-center
    flex
    flex-wrap
    text-sm;

  .btn {
    @apply
      px-3
      m-0.5
      bg-opacity-0
      hover:bg-opacity-40;
  }

  .btn.btn-primary {
    @apply
      text-black
      bg-gray-200
      bg-opacity-100;
  }
}
