.widget-input {
  @apply
    py-2 px-3
    flex-grow
    appearance-none
    outline-none
    bg-white
    text-gray-800
    border
    border-gray-400
    focus:border-hr-logo-green
    border-opacity-50
    focus:ring-hr-logo-green
    focus:ring
    focus:ring-opacity-50
    rounded-md
    w-full;
}

.widget-sub-form {
  @apply mb-5;

  summary {
    @apply
      mb-3;
  }
}

.fields-wrapper {
  @apply
    grid grid-cols-12 gap-3;
}

.fields-wrapper > .widget-sub-form:last-child {
  @apply mb-0;
}

.widget-error {
  color: red;
}
