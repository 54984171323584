/**
 * Form submissions preview.
 */

.step-preview {
  @apply
    col-span-2
    md:col-span-12;
}

.preview-heading {
  @apply
    text-xl
    font-bold;
}

.form-submission-preview {
  @apply
    md:col-start-2
    col-span-2
    md:col-span-8
    mb-6;
  
  summary {
    @apply
      font-semibold;
  }
  iframe {
    @apply
      min-w-full
      min-h-screen;
  }
}
