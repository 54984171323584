.attachment-section-heading {
  @apply
    text-xl
    font-bold;
}

.attachment-section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.attachment-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.attachment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: flex-start;

    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #aaa;
    column-gap: 0.5rem;

}

.attachment-label {
    margin-right: auto;
    overflow-wrap: anywhere;
    @apply
        text-hr-logo-green-dark-2
        hover:text-hr-logo-green-dark-1
        hover:underline
        ;
}
.attachment-meta>div {
    white-space: nowrap;
    @apply text-sm;
}

.attachment-head {
    @apply text-lg;
}

.attachment-form {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.attachment-form-header {
    @apply text-lg;
}
.attachment-form>input {
    margin-top: 1rem;
    margin-bottom: 1rem;
    align-self: stretch;
}
.attachment-buttons {
    display: flex;
    flex-direction: rw;
    justify-self: flex-end;
}
.attachment-buttons>*:first-child {
    margin-left: 0px;
}
