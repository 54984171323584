/**
 * @file
 * Student select modal styling.
 */

.modal .student-select-wrapper {
  @apply
    self-start
    mt-12;
  
  .modal-heading,
  .modal-body,
  .modal-buttons {
    @apply
      md:col-span-10
      md:col-start-2
      lg:col-span-6
      lg:col-start-4;
  }
}
