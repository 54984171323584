.step-1 {
    .form-picker-container {
        @apply 
        col-span-2
        md:col-span-4;

        .form-type {
          @apply
          bg-hr-background-light-1;
        }

        .form-picker {
            @apply
              text-sm
              top-16
              md:sticky
              pt-5
              -mt-10;

            @media (min-width: 768px) {
              /**
              * (Screen height) - (Page header line-height) - (Page header
              * border-bottom) - (HR header height) + (Anti-jank jogging
              * around number that's required for some reason).
              */
              max-height: calc(100vh - 4rem - 1px - 5rem);
              overflow-y: auto;
            }
        }
    }

  .form-picker-select-all {
    @apply
      text-sm
  }

  .recent-form-header {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
  }

  /**
   * Because positioning of elements inside the header must match the (grid
   * layout) elements in step-container, the header in Step 1 must also be a
   * grid layout.
   * @todo: change all header areas so they are grid layout?
   */
  .page-header {
    @apply
      grid
      grid-cols-12
      px-0
      lg:px-0;

    .page-heading {
      @apply
        col-span-6
        ml-3
        lg:ml-5
        md:col-span-4;
    }

    .mm-land {
      @apply
        row-start-2
        md:row-start-1
        col-start-1
        md:col-start-5
        col-span-12
        md:col-span-5
        pb-3
        md:pb-0
        ml-3
        lg:ml-5
        flex
        gap-3;
    }

    .create-forms-button {
      @apply
        mr-3
        lg:mr-5
        row-start-1
        col-start-7
        col-span-6
        md:col-start-10
        md:col-span-3;
    }

    .edit-forms-button {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%233f403d'%3E%3Cpath d='M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z' /%3E%3Cpath fill-rule='evenodd' d='M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z' clip-rule='evenodd' /%3E%3C/svg%3E");
    }
    .copy-forms-button {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%233f403d'%3E%3Cpath d='M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z' /%3E%3Cpath d='M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z' /%3E%3C/svg%3E");
    }
    .export-forms-button {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%233f403d'%3E%3Cpath fill-rule='evenodd' d='M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z' clip-rule='evenodd' /%3E%3C/svg%3E");
    }
    .delete-forms-button {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%233f403d'%3E%3Cpath fill-rule='evenodd' d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z' clip-rule='evenodd' /%3E%3C/svg%3E");
    }
    .request-sigs-button {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233f403d' class='w-6 h-6'%3e%3cpath d='M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z'/%3e%3cpath d='M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z'/%3e%3c/svg%3e");
    }
  }
}

/* Use 'forms-spa-standalone' to remove HR header height from
   height calculations. */
.forms-spa-standalone .step-1 .form-picker {
  @media (min-width: 768px) {
      /**
      * (Screen height) - (Page header line-height) - (Page header
      * border-bottom).
      */
    max-height: calc(100vh - 4rem - 1px);
  }
}

.step-1-right {
  @apply
    col-span-2 md:col-span-8
    relative;

  @media (min-width: 768px) {
    /**
    * (Screen height) - (Page header line-height) - (Page header
    * border-bottom) - (HR header height).
    *
    * This invocation is to fix a bug where form types 'jog' up and down
    * when saving after re-ordering them. It was nigh impossible to get
    * the correct max-height on .form-picker, so set a min-height on
    * Recent Forms instead.
    */
    min-height: calc(100vh - 4rem - 1px - 5rem);
  }
}

/* Step 1 - Search combos above Recent Forms */

.search-selects {
  @apply
    grid
    grid-rows-3
    md:grid-cols-3
    md:grid-rows-1
    gap-5
    mb-3;

  label {
    @apply
      text-sm;
  }
}

.search-form {
  @apply
    mb-5;
}

/* Step 1 - Create forms button */

.create-forms-button {
  @apply
    m-0
    justify-self-end
    self-center;
}

/* Step 1 - Recent forms */

.recent-form-list {
  .form-abbr {
    @apply
      flex-shrink-0
      w-14
      h-14;
  }
}

.form-label {
  @apply
    text-center
    flex
    gap-3
    justify-center
    items-center;
}

.student-form {
  @apply
    flex
    gap-3
    mb-1
    items-center
    p-2
    cursor-pointer
    hover:bg-hr-background-light;

  label {
    @apply
      flex
      gap-3
      items-center;
  }

  .info {
    @apply font-light leading-5 flex-grow;

    h3 {
      @apply font-bold;
    }
  }

  .recent-form-info-wrapper {
    @apply
      flex
      flex-grow;
  }

  .recent-form-extra {
    @apply
      flex
      flex-col
      justify-items-start
      text-xs
      font-light;
    align-items: end;

    color: rgb(41, 43, 44);

    .time {
        text-align: right;
        min-width: 6rem;
    }

    .recent-form-indicators {
        @apply flex flex-row;
        gap: 0.3rem;
        justify-content: end;
        align-items: center;

        .recent-form-comments, .recent-form-attachments, .recent-form-signatures {
            @apply flex flex-row;
            align-items: center;

            .image-replace {
                width: 1.2rem;
                height: 1.2rem;
            }
        }

        .recent-form-comments {
            .image-replace {
                /* TODO: Use a suitable icon */
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' class='w-5 h-5'%3E%3Cpath d='M3.505 2.365A41.369 41.369 0 019 2c1.863 0 3.697.124 5.495.365 1.247.167 2.18 1.108 2.435 2.268a4.45 4.45 0 00-.577-.069 43.141 43.141 0 00-4.706 0C9.229 4.696 7.5 6.727 7.5 8.998v2.24c0 1.413.67 2.735 1.76 3.562l-2.98 2.98A.75.75 0 015 17.25v-3.443c-.501-.048-1-.106-1.495-.172C2.033 13.438 1 12.162 1 10.72V5.28c0-1.441 1.033-2.717 2.505-2.914z' /%3E%3Cpath d='M14 6c-.762 0-1.52.02-2.271.062C10.157 6.148 9 7.472 9 8.998v2.24c0 1.519 1.147 2.839 2.71 2.935.214.013.428.024.642.034.2.009.385.09.518.224l2.35 2.35a.75.75 0 001.28-.531v-2.07c1.453-.195 2.5-1.463 2.5-2.915V8.998c0-1.526-1.157-2.85-2.729-2.936A41.645 41.645 0 0014 6z' /%3E%3C/svg%3E%0A");
            }
        }

        .recent-form-attachments {
            .image-replace {
                /* TODO: Use a suitable icon */
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' class='w-5 h-5'%3E%3Cpath fill-rule='evenodd' d='M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
            }
        }

        .recent-form-signatures {
            .image-replace {
                /* TODO: Use a suitable icon */
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cpath d='M623.2 192c-51.8 3.5-125.7 54.7-163.1 71.5-29.1 13.1-54.2 24.4-76.1 24.4-22.6 0-26-16.2-21.3-51.9 1.1-8 11.7-79.2-42.7-76.1-25.1 1.5-64.3 24.8-169.5 126L192 182.2c30.4-75.9-53.2-151.5-129.7-102.8L7.4 116.3C0 121-2.2 130.9 2.5 138.4l17.2 27c4.7 7.5 14.6 9.7 22.1 4.9l58-38.9c18.4-11.7 40.7 7.2 32.7 27.1L34.3 404.1C27.5 421 37 448 64 448c8.3 0 16.5-3.2 22.6-9.4 42.2-42.2 154.7-150.7 211.2-195.8-2.2 28.5-2.1 58.9 20.6 83.8 15.3 16.8 37.3 25.3 65.5 25.3 35.6 0 68-14.6 102.3-30 33-14.8 99-62.6 138.4-65.8 8.5-.7 15.2-7.3 15.2-15.8v-32.1c.2-9.1-7.5-16.8-16.6-16.2z'/%3E%3C/svg%3E");
            }
        }
    }
  }
}

.preferred-name-paren {
    font-weight: 200;
}

.recent-forms-empty {
    text-align: center;
}
