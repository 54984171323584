.form-type {
  @apply
    flex
    gap-3
    relative
    mb-1
    items-center
    hover:bg-hr-background-light
    hover:cursor-pointer;

  .form-abbr {
    @apply
      hover:cursor-pointer
      flex-shrink-0
      w-12
      h-12
      text-xs;
  }

  .form-checkbox {
    @apply
      hover:cursor-pointer
      absolute
      left-5;
  }

  .form-arrows {
    @apply
      hover:cursor-pointer
      absolute
      left-4
      flex flex-col
      gap-2
    ;
  }

  .form-label {
    @apply
      p-2
      pl-12
      flex-grow
      justify-start
      text-left
      hover:cursor-pointer;
  }

  .arrow-up-icon,
  .arrow-down-icon,
  .arrow-up-icon-first,
  .arrow-down-icon-last {
    @apply
      block
      w-6
      h-5
      bg-center
      bg-no-repeat;
  }

  .arrow-up-icon {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5"><path fill-rule="evenodd" d="M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z" clip-rule="evenodd" /></svg>');
  }
  .arrow-down-icon {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5"><path fill-rule="evenodd" d="M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z" clip-rule="evenodd" /></svg>');
  }

  /* @todo: translateY() might be simpler for these, but didn't work as
    expected in a quick test. */
  &.moved-down {
    animation: form-type-moved-down .3s;
  }
  &.moved-up-0 {
    animation: form-type-moved-up .3s;
  }
  &.moved-up-1 {
    animation: form-type-moved-up-1 .3s;
  }
  /**
   * select-all makes the target one more than we might expect.
   * if select-all ever moves, this needs to be updated too.
   */
  &.moved-down:nth-of-type(3) .arrow-up-icon {
    animation: .3s opacity-fade-in;
  }
  &:is(.moved-up-0.moved-up-1):nth-of-type(2) .arrow-up-icon {
    animation: .3s opacity-fade-out;
  }
  &:is(.moved-up-0, .moved-up-1):nth-last-of-type(2) .arrow-down-icon {
    animation: .3s opacity-fade-in;
  }
  &.moved-down:nth-last-of-type(1) .arrow-down-icon {
    animation: .3s opacity-fade-in;
  }
}

.form-picker-select-all {
  @apply pl-5 mb-3;
  .select-all-label {
    @apply mx-3;
  }
}

/* Form type re-ordering */

.form-picker-re-order.button,
.form-picker-re-order-save.button {
  @apply
    flex
    sticky
    gap-1
    items-center
    text-sm
    px-3
    py-1
    m-1
    ml-auto
    mr-6
    top-[4.7rem]
    z-10;

  .re-order-icon {
    @apply
      block
      w-4
      h-4;

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-6 h-6'%3E%3Cpath fill-rule='evenodd' d='M6.97 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06L8.25 4.81V16.5a.75.75 0 01-1.5 0V4.81L3.53 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zm9.53 4.28a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V7.5a.75.75 0 01.75-.75z' clip-rule='evenodd' /%3E%3C/svg%3E");
  }
}

/* Animate form re-ordering for the form moving down in the ordering. */
@keyframes form-type-moved-down {
  0% {
    position: relative;
    top: -4.25rem;
  }
  100% {
    position: relative;
    top: 0;
  }
}

/* Animate form re-ordering for the form moving up in the ordering. */
@keyframes form-type-moved-up {
  0% {
    position: relative;
    top: 4.25rem;
  }
  100% {
    position: relative;
    top: 0;
  }
}

@keyframes opacity-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* This does the same as `form-type-moved-up`, they have different names
   so the re-orderin may be triggered multiple times for the same
   form design. */
@keyframes form-type-moved-up-1 {
  0% {
    position: relative;
    top: 4.45rem;
  }
  100% {
    position: relative;
    top: 0;
  }
}