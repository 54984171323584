@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

details > summary {
  cursor: pointer;
}

button:focus {
  outline-color: #f1f2f2;
  outline-width: 2px;
  outline-offset: 2px;
  outline-style: solid;
}
button.btn-primary:focus {
  outline-color: #678f2e;
}
.pager button.btn-primary:focus {
  outline-color: #f1f2f2;
}

/* Copy & paste hack to unjustly rid forms of their beautiful spin buttons on
   number inputs. */

/* Chrome, Safari, Edge, Opera */
/* HACK: Combining these with a comma instead of making them separate rules
* causes PostCSS to replace it with a `:is(...)` selector which does not work.
*/
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* @todo: looks awful, replace with a nice SVG icon. */
summary::marker {
  @apply
    text-xs;
  color: #aaa;
}

/* Headings are allowed inside summary, but block level elements
   break the formatting. */
summary > * {
  display: inline;
}

/* Prevent weird, ugly fill and borders on meter elements. */
meter {
  border: revert;
}

.container {
  @apply mx-auto;
}

/**
 * form-abbr crops up in multiple components on multiple steps.
 *
 * It doesn't really fit anywhere else right now, so it can be here.
 */
.form-abbr {
  @apply
    rounded-md
    inline-flex
    justify-center
    items-center
    overflow-hidden
    text-white
    text-sm
    no-underline;
}

/**
 * Party like it's 2004 with this image replacement, necessary because the
 * underlying library Concur uses doesn't support SVG.
 *
 * Someone else with the same issue:
 * https://discourse.purescript.org/t/small-gig-developing-a-poc-ui-in-svg-using-purescript-halogen/1879/8
 */
.image-replace {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 66%;
  text-indent: -9999px;
  overflow: hidden;
  white-space: nowrap;
}

/* Quick hack to get the right padding on the vertical elipsis button in the recent forms section */
.btn.vdots {
    padding-right: .8rem;
    padding-left: 1rem;
}
