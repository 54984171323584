/* On larger screen sizes the work queue filling with students gives a
  useful clue about what will happen when the user starts picking forms
  to fill. On mobile it just gets in the way. */
.step-2a {
  .workqueue {
    @apply
      hidden
      md:block
      md:row-span-3;
  }
}

/* Span over the teacher and semester picker + the form picker. */
.step-2b {
  .workqueue {
    @apply
      md:row-span-3;
  }
}

.step-2a-right {
  @apply col-span-2 md:col-span-9;
}

.step-2b-right {
  @apply flex flex-col relative col-span-2 md:col-span-9;
}

.student-sel-check {
    @apply mb-2;
    display: flex;
    flex-direction: row;
}

.student-tags {
    display: flex;
    flex-direction: row;
    gap: 0.3rem;
    align-items: center;
}

.student-tag {
    border-radius: 0.2rem;
    padding: 0 0.3rem;
    color: rgb(107, 107, 107);

    @apply
      text-xs
      font-medium;
}
