/**
 * @file
 * Signature modal styling, separate to widget styling.
 *
 * @see signature.css
 */

.signature-form-info-container {
  @apply
    grid
    grid-cols-2
    relative
    px-3
    md:p-0
    col-span-2
    md:col-span-8
    md:col-start-3
    lg:col-start-4
    lg:col-span-6
    gap-3;

  .widget-input {
    @apply
      col-span-2
      md:col-span-1;
  }

  #student-email {
    @apply
      md:col-span-2;
  }
}

.signature-form .modal-buttons {
  @apply
    row-start-auto
    md:col-start-7
    md:col-span-3;

  .btn {
    @apply
      flex-grow;
  }
}

.signature-form-pad-container {
  @apply
    relative
    col-span-2
    md:col-span-8
    md:col-start-3
    lg:col-start-4
    lg:col-span-6;
}

.signature-form-pad-canvas {
  @apply
    bg-white
    border-dashed
    border-b-4
    border-gray-300
    w-full;
  min-height: 13rem;
  max-height: 15rem;
}

.signature-insert-default {
  @apply
    mx-3
    md:mx-0
    col-span-2
    md:col-span-4
    md:col-start-5;
}

.signature-clear {
  @apply
    top-1
    right-1
    absolute;
}

/* Single e-mail / e-mail all send modal shared classes. */

.signature-email-single-form,
.signature-email-all-form {
  @apply
    p-3;

  .email-address,
  .email-all-rows {
    @apply
      col-span-2
      row-start-2
      md:col-start-4
      md:col-span-6
      lg:col-start-5
      lg:col-span-4;
  }
  .signature-comment-container {
    @apply
      flex flex-col
      col-span-2
      row-start-3
      md:col-start-4
      md:col-span-6
      lg:col-start-5
      lg:col-span-4;
  }

  .modal-buttons {
      @apply row-start-4;
  }
}

/* Signatures - e-mail all modal. */

.email-all-rows {
  @apply
    flex
    flex-wrap
    gap-3;
}

.email-all-row {
  @apply
    flex
    flex-grow
    items-center
    gap-3
    w-full;

  .email-wrapper {
    @apply
      flex-grow;
  }

  label {
    @apply
      block
      mb-1;
  }
}

.email-all-row-checkbox {
  @apply
    self-start
    mt-1;
}
