.comment-section-heading {
  @apply
    text-xl
    font-bold;
}

.comment-section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.comment {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #aaa;
    row-gap: 0.5rem;
}

.comment-head {
    display: flex;
    flex-direction: row;
    align-items: center;

    .comment-poster {
        @apply text-sm;
    }
    .comment-times, .comment-is-public {
        @apply text-sm;
        margin-left: 0.5rem;
    }
    .comment-times::before, .comment-is-public::before {
        margin-right: 0.5rem;
        content: "—";
        @media (max-width: 500px) {
            display: none;
        }
    }
    .dropdown-container {
        margin-left: auto;
        .dropdown-menu {
            z-index: 100;
        }
    }
}

.comment-form {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.comment-form-header {
    @apply text-lg;
}
.comment-form>textarea {
    align-self: stretch;
    @apply
        appearance-none
        outline-none
        bg-white
        text-gray-800
        border
        border-gray-400
        focus:border-hr-logo-green
        border-opacity-50
        focus:ring-hr-logo-green
        focus:ring
        focus:ring-opacity-50
        rounded-md;
}
.comment-buttons {
    display: flex;
    flex-direction: rw;
}
.comment-buttons>*:first-child {
    margin-left: 0px;
}
