.expectation-picker.modal-grid {
  @apply
    mt-24
    self-start;
}

.expectation-picker {
  .tabs,
  .expectation-picker-body,
  .expectation-picker-buttons {
    @apply
      px-3
      col-span-2
      md:col-span-8
      md:col-start-3
      lg:col-span-6
      lg:col-start-4
  }

  /**
   * Checkbox and labels.
   *
   * Weird DOM structure here. Two nested .expectation-category divs,
   * then a div with no class.
   * @TODO: look into the PureScript code and fix (unless there's a
   * good reason!).
   */
  .expectation-category > div[class=""],
  .expectation-list > div[class=""] {
    @apply
      flex
      gap-2
      leading-5;

    input[type="checkbox"] {
      @apply
        flex-shrink-0
        mt-[.15rem];
    }

    label {
      @apply
        mx-0;
    }

  }

  nav.tabs {
    @apply
      gap-6
      flex
      row-start-1;

    .active {
      @apply
        font-semibold;
    }
  }

  .expectation-picker-body {
    @apply
      row-start-2;

    .widget-select-container {
      @apply
        mb-3;
    }
  }

  .expectation-list div {
    @apply
      mb-3;
  }
  
  .expectation-list h1 {
    @apply
        mb-2 mt-6
        text-2xl;
  }
  .expectation-list h2 {
    @apply
        text-lg;
  }
}
