.loading-spinner {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='30' cy='50' fill='%233f403d' r='20'%3E%3Canimate attributeName='cx' repeatCount='indefinite' dur='1s' keyTimes='0;0.5;1' values='30;70;30' begin='-0.5s'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='70' cy='50' fill='%2394d137' r='20'%3E%3Canimate attributeName='cx' repeatCount='indefinite' dur='1s' keyTimes='0;0.5;1' values='30;70;30' begin='0s'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='30' cy='50' fill='%233f403d' r='20'%3E%3Canimate attributeName='cx' repeatCount='indefinite' dur='1s' keyTimes='0;0.5;1' values='30;70;30' begin='-0.5s'%3E%3C/animate%3E%3Canimate attributeName='fill-opacity' values='0;0;1;1' calcMode='discrete' keyTimes='0;0.499;0.5;1' dur='1s' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: -9999px;
  min-height: 3rem;
  max-height: 12rem;
  overflow: hidden;
  white-space: nowrap;

  @apply
    flex flex-grow
    col-span-9
    self-center
    mt-5;
}
.step-3 .loading-spinner {
  /* Make room for the workqueue. */
  @apply
    col-span-9;
}
.step-3-right .fields-wrapper .loading-spinner {
  @apply
    col-span-12;
}

.loading-overlay {
    background-color: rgba(0, 0, 0, 0.27);

    z-index: 42;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: fixed;

    @apply flex;

    .loading-spinner {
        opacity: 1;
        @apply self-center;
    }
}
