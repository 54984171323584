.step-container {
  @apply
    grid
    gap-0
    md:grid-cols-12
    grid-cols-2
    flex-grow
    bg-hr-background-light-1
    content-start;

  height: fit-content;

  .page-header {
    @apply
      px-3
      lg:px-5
      flex
      flex-wrap
      lg:flex-nowrap
      bg-hr-background-light
      border-b border-gray-300
      md:h-16
      md:box-border
      sticky top-0 self-start z-40
      col-span-full
      items-center;

    .btn-group {
      @apply
        py-0
        flex
        flex-wrap
        max-w-full
        lg:flex-nowrap
        gap-3
        items-baseline;
    }

    .btn {
      @apply
        m-0;
    }
  }

  .error {
      @apply
        col-span-2 md:col-span-9
        pt-2 pb-2 pl-5 pr-5
        bg-yellow-200
        ;
  }
}

.step-container > .loading-spinner {
  @apply
    my-20;
}

/* Styling for all steps */

.step-1-right,
.step-2a-right,
.step-2b-right,
.step-3-right,
.step-preview {
  @apply
    bg-white
    border-l
    border-hr-background-light
    py-5
    px-3
    lg:px-5
    min-h-[20rem];
}
