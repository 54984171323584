.btn-group {
  @apply
    flex-shrink-0;
}
.btn {
  @apply
    bg-gray-200
    px-5
    py-2
    m-1
    leading-snug
    rounded-md
    cursor-pointer
    hover:bg-gray-300;

  &.btn-primary {
    @apply
      bg-hr-logo-green-dark-1
      hover:bg-hr-logo-green-dark-2
      text-white;
  }
}

.btn[disabled] {
  @apply
    opacity-50
    hover:bg-gray-200;
  
  &.btn-primary {
    @apply
      hover:bg-hr-logo-green-dark-1;
  }
}

input[type=checkbox][disabled] {
    background-color: #DDD;
    border-color: #999;
}

.employer-edit-btn {
    max-width: 250px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
