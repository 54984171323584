.dropdown-button {
  @apply
    text-2xl
    p-1
    pr-0
    text-center;
}
.page-header .dropdown-button {
  /* CSS IS FUN */
  position: relative;
  top: 5px;
}

.signature-widget .dropdown-container {
  @apply
    absolute
    right-1
    top-1
    md:right-0
    md:top-0;
}

.dropdown-container {
  @apply
    relative;
}

.dropdown-menu {
  @apply
    flex
    flex-col
    absolute
    bg-white
    border-hr-logo-green-dark-1
    drop-shadow-sm
    border
    rounded-md
    py-2
    top-11
    right-0;
  
  a, .dropdown-item {
    @apply
      bg-white
      px-6 py-3
      lg:py-1
      z-30
      lg:text-sm;
  }
  a {
    @apply
      hover:bg-hr-logo-green-dark-1
      hover:text-white
      hover:cursor-pointer;
    &.active {
      @apply
        bg-hr-logo-green-dark-1
        text-white;
    }
  }
}
