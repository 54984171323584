/**
 * @file
 * Teacher and semester selections at the top of every step (except 1).
 */

.teacher-semester-selections {
  @apply
    bg-hr-background-light
    border-b
    border-gray-300
    pl-5
    pr-5
    pt-2 /* <- match padding-bottom on last p > .hidden-select in container. */
    pb-1
    col-span-2
    md:col-span-9;

  .widget-select-container {
    @apply
      block
      lg:inline-block
      lg:mx-1;
  }

  .widget-select {
    min-width: 20rem;
  }

  p {
    @apply
      lg:py-3
      mr-1
      lg:inline-block;
  }

  .hidden-select {
    @apply
      inline-block;
    
    p {
      @apply
        py-2 /* <- on mobile, dont' jog around when swapped out for a BetterSelect™ */
        inline-block
        italic;
    }
  }
}

.step-3 .teacher-semester-selections {
  @apply
    border-b-0
    p-0
    bg-transparent;
}
