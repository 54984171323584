@import "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700";
details > summary {
  cursor: pointer;
}

button:focus {
  outline-offset: 2px;
  outline: 2px solid #f1f2f2;
}

button.btn-primary:focus {
  outline-color: #678f2e;
}

.pager button.btn-primary:focus {
  outline-color: #f1f2f2;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

summary::marker {
  color: #aaa;
  font-size: .75rem;
  line-height: 1rem;
}

summary > * {
  display: inline;
}

meter {
  border: revert;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.form-abbr {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  border-radius: .375rem;
  justify-content: center;
  align-items: center;
  font-size: .875rem;
  line-height: 1.25rem;
  text-decoration: none;
  display: inline-flex;
  overflow: hidden;
}

.image-replace {
  text-indent: -9999px;
  white-space: nowrap;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 66%;
  overflow: hidden;
}

.btn.vdots {
  padding-left: 1rem;
  padding-right: .8rem;
}

.step-container {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 252, 252, var(--tw-bg-opacity));
  flex-grow: 1;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-content: flex-start;
  gap: 0;
  display: grid;
}

@media (width >= 768px) {
  .step-container {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.step-container {
  height: -moz-fit-content;
  height: fit-content;
}

.step-container .page-header {
  z-index: 40;
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(241, 242, 242, var(--tw-bg-opacity));
  flex-wrap: wrap;
  grid-column: 1 / -1;
  align-self: flex-start;
  align-items: center;
  padding-left: .75rem;
  padding-right: .75rem;
  display: flex;
  position: sticky;
  top: 0;
}

@media (width >= 768px) {
  .step-container .page-header {
    box-sizing: border-box;
    height: 4rem;
  }
}

@media (width >= 1024px) {
  .step-container .page-header {
    flex-wrap: nowrap;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.step-container .page-header .btn-group {
  flex-wrap: wrap;
  align-items: baseline;
  gap: .75rem;
  max-width: 100%;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}

@media (width >= 1024px) {
  .step-container .page-header .btn-group {
    flex-wrap: nowrap;
  }
}

.step-container .page-header .btn {
  margin: 0;
}

.step-container .error {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  grid-column: span 2 / span 2;
  padding: .5rem 1.25rem;
}

@media (width >= 768px) {
  .step-container .error {
    grid-column: span 9 / span 9;
  }
}

.step-container > .loading-spinner {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.step-1-right, .step-2a-right, .step-2b-right, .step-3-right, .step-preview {
  --tw-border-opacity: 1;
  border-left-width: 1px;
  border-color: rgba(241, 242, 242, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  min-height: 20rem;
  padding: 1.25rem .75rem;
}

@media (width >= 1024px) {
  .step-1-right, .step-2a-right, .step-2b-right, .step-3-right, .step-preview {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.step-1 .form-picker-container {
  grid-column: span 2 / span 2;
}

@media (width >= 768px) {
  .step-1 .form-picker-container {
    grid-column: span 4 / span 4;
  }
}

.step-1 .form-picker-container .form-type {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 252, 252, var(--tw-bg-opacity));
}

.step-1 .form-picker-container .form-picker {
  margin-top: -2.5rem;
  padding-top: 1.25rem;
  font-size: .875rem;
  line-height: 1.25rem;
  top: 4rem;
}

@media (width >= 768px) {
  .step-1 .form-picker-container .form-picker {
    max-height: calc(100vh - 9rem - 1px);
    position: sticky;
    overflow-y: auto;
  }
}

.step-1 .form-picker-select-all {
  font-size: .875rem;
  line-height: 1.25rem;
}

.step-1 .recent-form-header {
  flex-direction: row;
  gap: .3rem;
  display: flex;
}

.step-1 .page-header {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding-left: 0;
  padding-right: 0;
  display: grid;
}

@media (width >= 1024px) {
  .step-1 .page-header {
    padding-left: 0;
    padding-right: 0;
  }
}

.step-1 .page-header .page-heading {
  grid-column: span 6 / span 6;
  margin-left: .75rem;
}

@media (width >= 768px) {
  .step-1 .page-header .page-heading {
    grid-column: span 4 / span 4;
  }
}

@media (width >= 1024px) {
  .step-1 .page-header .page-heading {
    margin-left: 1.25rem;
  }
}

.step-1 .page-header .mm-land {
  grid-column: 1 / span 12;
  grid-row-start: 2;
  gap: .75rem;
  margin-left: .75rem;
  padding-bottom: .75rem;
  display: flex;
}

@media (width >= 768px) {
  .step-1 .page-header .mm-land {
    grid-column: 5 / span 5;
    grid-row-start: 1;
    padding-bottom: 0;
  }
}

@media (width >= 1024px) {
  .step-1 .page-header .mm-land {
    margin-left: 1.25rem;
  }
}

.step-1 .page-header .create-forms-button {
  grid-column: 7 / span 6;
  grid-row-start: 1;
  margin-right: .75rem;
}

@media (width >= 768px) {
  .step-1 .page-header .create-forms-button {
    grid-column: 10 / span 3;
  }
}

@media (width >= 1024px) {
  .step-1 .page-header .create-forms-button {
    margin-right: 1.25rem;
  }
}

.step-1 .page-header .edit-forms-button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%233f403d'%3E%3Cpath d='M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z' /%3E%3Cpath fill-rule='evenodd' d='M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z' clip-rule='evenodd' /%3E%3C/svg%3E");
}

.step-1 .page-header .copy-forms-button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%233f403d'%3E%3Cpath d='M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z' /%3E%3Cpath d='M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z' /%3E%3C/svg%3E");
}

.step-1 .page-header .export-forms-button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%233f403d'%3E%3Cpath fill-rule='evenodd' d='M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z' clip-rule='evenodd' /%3E%3C/svg%3E");
}

.step-1 .page-header .delete-forms-button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%233f403d'%3E%3Cpath fill-rule='evenodd' d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z' clip-rule='evenodd' /%3E%3C/svg%3E");
}

.step-1 .page-header .request-sigs-button {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%233f403d' class='w-6 h-6'%3e%3cpath d='M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z'/%3e%3cpath d='M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z'/%3e%3c/svg%3e");
}

@media (width >= 768px) {
  .forms-spa-standalone .step-1 .form-picker {
    max-height: calc(100vh - 4rem - 1px);
  }
}

.step-1-right {
  grid-column: span 2 / span 2;
  position: relative;
}

@media (width >= 768px) {
  .step-1-right {
    grid-column: span 8 / span 8;
    min-height: calc(100vh - 9rem - 1px);
  }
}

.search-selects {
  grid-template-rows: repeat(3, minmax(0, 1fr));
  gap: 1.25rem;
  margin-bottom: .75rem;
  display: grid;
}

@media (width >= 768px) {
  .search-selects {
    grid-template-rows: repeat(1, minmax(0, 1fr));
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.search-selects label {
  font-size: .875rem;
  line-height: 1.25rem;
}

.search-form {
  margin-bottom: 1.25rem;
}

.create-forms-button {
  place-self: center end;
  margin: 0;
}

.recent-form-list .form-abbr {
  flex-shrink: 0;
  width: 3.5rem;
  height: 3.5rem;
}

.form-label {
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.student-form {
  cursor: pointer;
  align-items: center;
  gap: .75rem;
  margin-bottom: .25rem;
  padding: .5rem;
  display: flex;
}

.student-form:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 242, 242, var(--tw-bg-opacity));
}

.student-form label {
  align-items: center;
  gap: .75rem;
  display: flex;
}

.student-form .info {
  flex-grow: 1;
  font-weight: 300;
  line-height: 1.25rem;
}

.student-form .info h3 {
  font-weight: 700;
}

.student-form .recent-form-info-wrapper {
  flex-grow: 1;
  display: flex;
}

.student-form .recent-form-extra {
  color: #292b2c;
  flex-direction: column;
  place-items: end start;
  font-size: .75rem;
  font-weight: 300;
  line-height: 1rem;
  display: flex;
}

.student-form .recent-form-extra .time {
  text-align: right;
  min-width: 6rem;
}

.student-form .recent-form-extra .recent-form-indicators {
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: .3rem;
  display: flex;
}

.student-form .recent-form-extra .recent-form-indicators .recent-form-comments, .student-form .recent-form-extra .recent-form-indicators .recent-form-attachments, .student-form .recent-form-extra .recent-form-indicators .recent-form-signatures {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.student-form .recent-form-extra .recent-form-indicators .recent-form-comments .image-replace, .student-form .recent-form-extra .recent-form-indicators .recent-form-attachments .image-replace, .student-form .recent-form-extra .recent-form-indicators .recent-form-signatures .image-replace {
  width: 1.2rem;
  height: 1.2rem;
}

.student-form .recent-form-extra .recent-form-indicators .recent-form-comments .image-replace {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' class='w-5 h-5'%3E%3Cpath d='M3.505 2.365A41.369 41.369 0 019 2c1.863 0 3.697.124 5.495.365 1.247.167 2.18 1.108 2.435 2.268a4.45 4.45 0 00-.577-.069 43.141 43.141 0 00-4.706 0C9.229 4.696 7.5 6.727 7.5 8.998v2.24c0 1.413.67 2.735 1.76 3.562l-2.98 2.98A.75.75 0 015 17.25v-3.443c-.501-.048-1-.106-1.495-.172C2.033 13.438 1 12.162 1 10.72V5.28c0-1.441 1.033-2.717 2.505-2.914z' /%3E%3Cpath d='M14 6c-.762 0-1.52.02-2.271.062C10.157 6.148 9 7.472 9 8.998v2.24c0 1.519 1.147 2.839 2.71 2.935.214.013.428.024.642.034.2.009.385.09.518.224l2.35 2.35a.75.75 0 001.28-.531v-2.07c1.453-.195 2.5-1.463 2.5-2.915V8.998c0-1.526-1.157-2.85-2.729-2.936A41.645 41.645 0 0014 6z' /%3E%3C/svg%3E%0A");
}

.student-form .recent-form-extra .recent-form-indicators .recent-form-attachments .image-replace {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' class='w-5 h-5'%3E%3Cpath fill-rule='evenodd' d='M15.621 4.379a3 3 0 00-4.242 0l-7 7a3 3 0 004.241 4.243h.001l.497-.5a.75.75 0 011.064 1.057l-.498.501-.002.002a4.5 4.5 0 01-6.364-6.364l7-7a4.5 4.5 0 016.368 6.36l-3.455 3.553A2.625 2.625 0 119.52 9.52l3.45-3.451a.75.75 0 111.061 1.06l-3.45 3.451a1.125 1.125 0 001.587 1.595l3.454-3.553a3 3 0 000-4.242z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
}

.student-form .recent-form-extra .recent-form-indicators .recent-form-signatures .image-replace {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'%3E%3Cpath d='M623.2 192c-51.8 3.5-125.7 54.7-163.1 71.5-29.1 13.1-54.2 24.4-76.1 24.4-22.6 0-26-16.2-21.3-51.9 1.1-8 11.7-79.2-42.7-76.1-25.1 1.5-64.3 24.8-169.5 126L192 182.2c30.4-75.9-53.2-151.5-129.7-102.8L7.4 116.3C0 121-2.2 130.9 2.5 138.4l17.2 27c4.7 7.5 14.6 9.7 22.1 4.9l58-38.9c18.4-11.7 40.7 7.2 32.7 27.1L34.3 404.1C27.5 421 37 448 64 448c8.3 0 16.5-3.2 22.6-9.4 42.2-42.2 154.7-150.7 211.2-195.8-2.2 28.5-2.1 58.9 20.6 83.8 15.3 16.8 37.3 25.3 65.5 25.3 35.6 0 68-14.6 102.3-30 33-14.8 99-62.6 138.4-65.8 8.5-.7 15.2-7.3 15.2-15.8v-32.1c.2-9.1-7.5-16.8-16.6-16.2z'/%3E%3C/svg%3E");
}

.preferred-name-paren {
  font-weight: 200;
}

.recent-forms-empty {
  text-align: center;
}

.step-2a .workqueue {
  display: none;
}

@media (width >= 768px) {
  .step-2a .workqueue {
    grid-row: span 3 / span 3;
    display: block;
  }

  .step-2b .workqueue {
    grid-row: span 3 / span 3;
  }
}

.step-2a-right {
  grid-column: span 2 / span 2;
}

@media (width >= 768px) {
  .step-2a-right {
    grid-column: span 9 / span 9;
  }
}

.step-2b-right {
  flex-direction: column;
  grid-column: span 2 / span 2;
  display: flex;
  position: relative;
}

@media (width >= 768px) {
  .step-2b-right {
    grid-column: span 9 / span 9;
  }
}

.student-sel-check {
  flex-direction: row;
  margin-bottom: .5rem;
  display: flex;
}

.student-tags {
  flex-direction: row;
  align-items: center;
  gap: .3rem;
  display: flex;
}

.student-tag {
  color: #6b6b6b;
  border-radius: .2rem;
  padding: 0 .3rem;
  font-size: .75rem;
  font-weight: 500;
  line-height: 1rem;
}

.step-3 .page-header .btn-group {
  flex-wrap: wrap;
  gap: .25rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

@media (width >= 1024px) {
  .step-3 .page-header .btn-group {
    gap: .75rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.step-3 .page-header .btn-group .btn {
  flex-grow: 1;
}

@media (width >= 640px) {
  .step-3 .page-header .btn-group .btn {
    line-height: 1rem;
  }
}

@media (width >= 1024px) {
  .step-3 .page-header .btn-group .btn {
    line-height: 1.375;
  }
}

.step-3-right.form-submission {
  grid-column: span 2 / span 2;
  margin-bottom: 5rem;
}

@media (width >= 768px) {
  .step-3-right.form-submission {
    grid-column: span 9 / span 9;
  }
}

.step-3-right.form-submission textarea {
  min-height: 8rem;
}

.step-3-h-full-textarea textarea {
  height: 100%;
}

.form-updated-times {
  margin-top: .75rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

@media (width >= 768px) {
  .step-3 .page-header {
    flex-wrap: nowrap;
  }
}

.step-3 .page-headings {
  vertical-align: baseline;
  flex-wrap: wrap;
  flex-grow: 1;
  display: flex;
}

.step-3 .page-subheading {
  white-space: nowrap;
}

.step-3 .page-heading {
  line-height: 1.5;
}

@media (width >= 768px) {
  .step-3 .page-heading {
    max-height: 2rem;
    overflow: hidden;
  }
}

@media (width >= 1024px) {
  .step-3 .page-heading {
    max-height: 100%;
  }
}

.step-3-form {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: .75rem;
  padding-bottom: 2.5rem;
  display: grid;
}

.step-3-right .widget-select-container {
  flex-grow: 1;
}

.step-3-form .list .list-entry {
  flex-direction: column;
  gap: .75rem;
  padding: .75rem;
  display: flex;
}

.step-3-form .list .list-entry summary {
  margin-bottom: .75rem;
}

.step-3-form .list .list-entry:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.step-3-form .list .btn {
  align-self: flex-end;
}

.search-subform-select-wrapper {
  flex-wrap: wrap;
  column-gap: .75rem;
  display: flex;
}

.search-subform-select-wrapper label {
  width: 100%;
}

.step-3-scroll {
  width: 100%;
  scroll-padding-top: 5rem;
}

.search-subform {
  grid-column: span 12 / span 12;
}

.search-subform-search {
  margin-bottom: 1.25rem;
}

.search-subform-select-wrapper {
  margin-bottom: .75rem;
}

.student-subform {
  grid-column: span 12 / span 12;
}

.btn-group {
  flex-shrink: 0;
}

.btn {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  border-radius: .375rem;
  margin: .25rem;
  padding: .5rem 1.25rem;
  line-height: 1.375;
}

.btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.btn.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 143, 46, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.btn.btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(64, 89, 29, var(--tw-bg-opacity));
}

.btn[disabled] {
  opacity: .5;
}

.btn[disabled]:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.btn[disabled].btn-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 143, 46, var(--tw-bg-opacity));
}

input[type="checkbox"][disabled] {
  background-color: #ddd;
  border-color: #999;
}

.employer-edit-btn {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 250px;
  overflow-x: hidden;
}

.error-page h1 {
  font-size: 1.5rem;
  line-height: 2rem;
}

.page-heading {
  z-index: 100;
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 4rem;
}

.widget-heading {
  margin-bottom: .5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
}

.page-heading + .page-subheading {
  flex-basis: 100%;
  font-size: 1.25rem;
  font-weight: 200;
  line-height: 1.5;
}

.step-preview {
  grid-column: span 2 / span 2;
}

@media (width >= 768px) {
  .step-preview {
    grid-column: span 12 / span 12;
  }
}

.preview-heading {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.form-submission-preview {
  grid-column: span 2 / span 2;
  margin-bottom: 1.5rem;
}

@media (width >= 768px) {
  .form-submission-preview {
    grid-column: 2 / span 8;
  }
}

.form-submission-preview summary {
  font-weight: 600;
}

.form-submission-preview iframe {
  min-width: 100%;
  min-height: 100vh;
}

.widget-input {
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-border-opacity: .5;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  outline-offset: 2px;
  border-radius: .375rem;
  outline: 2px solid #0000;
  flex-grow: 1;
  width: 100%;
  padding: .5rem .75rem;
}

.widget-input:focus {
  --tw-border-opacity: 1;
  border-color: rgba(142, 198, 63, var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 198, 63, var(--tw-ring-opacity));
  --tw-ring-opacity: .5;
}

.widget-sub-form {
  margin-bottom: 1.25rem;
}

.widget-sub-form summary {
  margin-bottom: .75rem;
}

.fields-wrapper {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: .75rem;
  display: grid;
}

.fields-wrapper > .widget-sub-form:last-child {
  margin-bottom: 0;
}

.widget-error {
  color: red;
}

.workqueue {
  --tw-border-opacity: 1;
  border-color: rgba(241, 242, 242, var(--tw-border-opacity));
  grid-column: span 2 / span 2;
  align-self: flex-start;
  margin-bottom: .75rem;
  top: 4rem;
}

@media (width >= 768px) {
  .workqueue {
    grid-column: span 3 / span 3;
    max-height: calc(100vh - 9rem - 1px);
    margin-bottom: 0;
    position: sticky;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.workqueue li {
  list-style-type: none;
}

.workqueue .expand-collapse-buttons {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  gap: 1.25rem;
  padding-left: 1.25rem;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
}

.workqueue a {
  cursor: pointer;
}

.workqueue a:hover {
  --tw-text-opacity: 1;
  color: rgba(103, 143, 46, var(--tw-text-opacity));
  --tw-text-opacity: 1;
}

@media (width >= 768px) {
  .forms-spa-standalone .workqueue {
    max-height: calc(100vh - 4rem - 1px);
    overflow-y: auto;
  }
}

.workqueue-students {
  margin-bottom: .75rem;
}

@media (width >= 1024px) {
  .workqueue-students {
    margin-bottom: 1.25rem;
  }
}

.workqueue-students:empty {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  align-self: center;
  margin-top: 1.25rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: none;
}

@media (width >= 768px) {
  .workqueue-students:empty {
    display: block;
  }
}

.workqueue-students:empty:before {
  content: "";
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve'%3E%3Cstyle%3E%0Apath %7B fill: rgba(241, 242, 242, 0.5);%0A%7D%0A%3C/style%3E%3Cg%3E%3Cpath d='M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z'/%3E%3Cpath d='M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991 c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z'/%3E%3Cpath d='M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z '/%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 8rem;
  min-height: 3rem;
  max-height: 12rem;
  display: block;
  overflow: hidden;
}

.workqueue-students:empty:after {
  white-space: pre;
  content: "No students or forms have \a been selected yet";
  position: relative;
  top: 1rem;
}

.workqueue-student {
  border-bottom-width: 1px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.workqueue-student.no-forms {
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: 1.25rem;
}

.workqueue-student summary:hover {
  --tw-text-opacity: 1;
  color: rgba(103, 143, 46, var(--tw-text-opacity));
}

.workqueue-student .summary-student {
  padding: .75rem 2.5rem .75rem .25rem;
  position: relative;
}

@media (width >= 1024px) {
  .workqueue-student .summary-student {
    padding-left: .5rem;
  }
}

.workqueue-student .summary-student {
  left: .12rem;
}

.workqueue-student > details[open] .summary-student {
  padding-bottom: .25rem;
}

.workqueue-student .workqueue-student-forms li {
  --tw-text-opacity: .75;
  margin-left: 2rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.workqueue-student .workqueue-student-forms .student-form-section {
  margin-bottom: .75rem;
}

.workqueue-student .workqueue-student-forms .workqueue-form-no-sections a, .workqueue-student .workqueue-student-forms .student-form-section a {
  padding: .25rem .5rem;
  display: inline-flex;
}

.workqueue-student .workqueue-student-forms .summary-form a {
  width: calc(100% - .7rem);
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline;
}

.workqueue-student .workqueue-student-forms details[open] .summary-form a {
  padding-bottom: .25rem;
}

.workqueue-selected {
  font-weight: 700;
}

.workqueue-form-no-sections a {
  cursor: auto;
}

.workqueue-form-no-sections a:hover {
  color: currentColor;
}

.workqueue-form-no-sections:last-child {
  margin-bottom: .5rem;
}

.modal {
  z-index: 9999;
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  justify-content: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  overflow-y: auto;
}

.modal .modal-close {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  display: block;
  position: absolute;
  top: .75rem;
  right: .75rem;
  overflow: hidden;
}

@media (width >= 768px) {
  .modal .modal-close {
    top: 1.25rem;
    right: 1.25rem;
  }
}

.modal .modal-close {
  text-indent: -9999px;
  white-space: nowrap;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z' fill='%2360625dff'%3E%3C/path%3E%3C/svg%3E");
}

.modal .modal-heading {
  font-size: 1.25rem;
  font-weight: 200;
  line-height: 1.75rem;
}

.modal-col {
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  max-width: 1000px;
  margin-top: .5rem;
  display: flex;
}

.modal-col > .modal-heading {
  margin-bottom: 2rem;
}

.modal-col > button, .modal-col > .modal-buttons {
  align-self: end;
}

.modal-col .modal-heading {
  margin-top: 10px;
  margin-bottom: 20px;
}

.modal-col.modal-narrow {
  max-width: 600px;
}

.modal-col.modal-wide {
  max-width: 1200px;
}

.modal-grid {
  flex-grow: 1;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: center;
  align-self: center;
  align-items: center;
  gap: 1.5rem .75rem;
  padding-top: .75rem;
  padding-bottom: .75rem;
  display: grid;
}

@media (width >= 768px) {
  .modal-grid {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

.modal-grid .modal-heading, .modal-grid .modal-body, .modal-grid .modal-buttons {
  grid-column: span 2 / span 2;
  padding-left: .75rem;
  padding-right: .75rem;
}

@media (width >= 768px) {
  .modal-grid .modal-heading, .modal-grid .modal-body, .modal-grid .modal-buttons {
    grid-column: 4 / span 6;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 1024px) {
  .modal-grid .modal-heading, .modal-grid .modal-body, .modal-grid .modal-buttons {
    grid-column: 5 / span 4;
  }
}

.modal-grid .modal-heading {
  grid-row-start: 1;
  line-height: 1;
}

.modal-grid .modal-body {
  grid-row-start: 2;
}

.modal-grid .modal-buttons {
  grid-row-start: 3;
  justify-content: flex-end;
}

.modal-buttons {
  gap: .75rem;
  display: flex;
}

.modal-buttons .btn {
  flex: 1;
  margin: 0;
}

@media (width >= 1024px) {
  .modal-buttons .btn {
    flex: 0 auto;
  }
}

.signature-form-info-container {
  grid-column: span 2 / span 2;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: .75rem;
  padding-left: .75rem;
  padding-right: .75rem;
  display: grid;
  position: relative;
}

@media (width >= 768px) {
  .signature-form-info-container {
    grid-column: 3 / span 8;
    padding: 0;
  }
}

@media (width >= 1024px) {
  .signature-form-info-container {
    grid-column: 4 / span 6;
  }
}

.signature-form-info-container .widget-input {
  grid-column: span 2 / span 2;
}

@media (width >= 768px) {
  .signature-form-info-container .widget-input {
    grid-column: span 1 / span 1;
  }

  .signature-form-info-container #student-email {
    grid-column: span 2 / span 2;
  }
}

.signature-form .modal-buttons {
  grid-row-start: auto;
}

@media (width >= 768px) {
  .signature-form .modal-buttons {
    grid-column: 7 / span 3;
  }
}

.signature-form .modal-buttons .btn {
  flex-grow: 1;
}

.signature-form-pad-container {
  grid-column: span 2 / span 2;
  position: relative;
}

@media (width >= 768px) {
  .signature-form-pad-container {
    grid-column: 3 / span 8;
  }
}

@media (width >= 1024px) {
  .signature-form-pad-container {
    grid-column: 4 / span 6;
  }
}

.signature-form-pad-canvas {
  --tw-border-opacity: 1;
  border-style: dashed;
  border-bottom-width: 4px;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  width: 100%;
  min-height: 13rem;
  max-height: 15rem;
}

.signature-insert-default {
  grid-column: span 2 / span 2;
  margin-left: .75rem;
  margin-right: .75rem;
}

@media (width >= 768px) {
  .signature-insert-default {
    grid-column: 5 / span 4;
    margin-left: 0;
    margin-right: 0;
  }
}

.signature-clear {
  position: absolute;
  top: .25rem;
  right: .25rem;
}

.signature-email-single-form, .signature-email-all-form {
  padding: .75rem;
}

.signature-email-single-form .email-address, .signature-email-single-form .email-all-rows, .signature-email-all-form .email-address, .signature-email-all-form .email-all-rows {
  grid-column: span 2 / span 2;
  grid-row-start: 2;
}

@media (width >= 768px) {
  .signature-email-single-form .email-address, .signature-email-single-form .email-all-rows, .signature-email-all-form .email-address, .signature-email-all-form .email-all-rows {
    grid-column: 4 / span 6;
  }
}

@media (width >= 1024px) {
  .signature-email-single-form .email-address, .signature-email-single-form .email-all-rows, .signature-email-all-form .email-address, .signature-email-all-form .email-all-rows {
    grid-column: 5 / span 4;
  }
}

.signature-email-single-form .signature-comment-container, .signature-email-all-form .signature-comment-container {
  flex-direction: column;
  grid-column: span 2 / span 2;
  grid-row-start: 3;
  display: flex;
}

@media (width >= 768px) {
  .signature-email-single-form .signature-comment-container, .signature-email-all-form .signature-comment-container {
    grid-column: 4 / span 6;
  }
}

@media (width >= 1024px) {
  .signature-email-single-form .signature-comment-container, .signature-email-all-form .signature-comment-container {
    grid-column: 5 / span 4;
  }
}

.signature-email-single-form .modal-buttons, .signature-email-all-form .modal-buttons {
  grid-row-start: 4;
}

.email-all-rows {
  flex-wrap: wrap;
  gap: .75rem;
  display: flex;
}

.email-all-row {
  flex-grow: 1;
  align-items: center;
  gap: .75rem;
  width: 100%;
  display: flex;
}

.email-all-row .email-wrapper {
  flex-grow: 1;
}

.email-all-row label {
  margin-bottom: .25rem;
  display: block;
}

.email-all-row-checkbox {
  align-self: flex-start;
  margin-top: .25rem;
}

.modal .student-select-wrapper {
  align-self: flex-start;
  margin-top: 3rem;
}

@media (width >= 768px) {
  .modal .student-select-wrapper .modal-heading, .modal .student-select-wrapper .modal-body, .modal .student-select-wrapper .modal-buttons {
    grid-column: 2 / span 10;
  }
}

@media (width >= 1024px) {
  .modal .student-select-wrapper .modal-heading, .modal .student-select-wrapper .modal-body, .modal .student-select-wrapper .modal-buttons {
    grid-column: 4 / span 6;
  }
}

.widget-select {
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-border-opacity: .5;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  outline-offset: 2px;
  border-radius: .375rem;
  outline: 2px solid #0000;
  flex-grow: 1;
  width: 100%;
  padding: .5rem .75rem;
  display: flex;
}

.widget-select:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(142, 198, 63, var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 198, 63, var(--tw-ring-opacity));
  --tw-ring-opacity: .5;
}

.widget-select-input {
  border-style: none;
  flex-grow: 1;
  width: 100%;
  padding: 0 1.5rem 0 0;
}

.widget-select-input:focus {
  outline-offset: 2px;
  border-style: none;
  outline: 2px solid #0000;
}

.widget-select-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
}

.widget-select-input.selected {
  background-position: right .2rem center;
}

.widget-select-input.not-selected {
  background-position: 100%;
}

.widget-select-container .widget-select-clear-button {
  white-space: nowrap;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 1;
  width: 1.25rem;
  overflow: hidden;
}

.widget-select-container .widget-select-clear-button:hover {
  background-color: #0000;
}

.widget-select-container .widget-select-clear-button {
  text-indent: -9999px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='%23a0aec0' %3E%3Cpath d='M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z' /%3E%3C/svg%3E");
  min-width: 1.25rem;
}

.widget-select-container {
  flex-grow: 0;
  flex-shrink: 1;
  display: flex;
  position: relative;
}

.widget-select-dropdown, .widget-select-error {
  z-index: 50;
  --tw-border-opacity: 1;
  border-width: 0 1px 1px;
  border-color: rgba(142, 198, 63, var(--tw-border-opacity));
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
  flex-direction: column;
  width: 100%;
  max-height: 24rem;
  display: flex;
  position: absolute;
  top: 2rem;
  overflow-y: auto;
}

.widget-select-group {
  flex-direction: column;
  display: flex;
}

.widget-select-group-header {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: .25rem .5rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.widget-select-loading, .widget-select-error {
  --tw-border-opacity: 1;
  border-width: 0 1px 1px;
  border-color: rgba(142, 198, 63, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
  padding: .25rem .5rem;
}

.widget-select-container .widget-select-option {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: .25rem .5rem;
}

.widget-select-container .widget-select-option:hover, .widget-select-container .widget-select-option.active {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 143, 46, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.widget-select-container .widget-select-option:first-child {
  margin-top: .25rem;
}

.widget-select-dropdown .loading-spinner {
  z-index: 50;
  width: 4rem;
  margin: 1.25rem .25rem .25rem;
}

.dropdown-button {
  text-align: center;
  padding: .25rem 0 .25rem .25rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

.page-header .dropdown-button {
  position: relative;
  top: 5px;
}

.signature-widget .dropdown-container {
  position: absolute;
  top: .25rem;
  right: .25rem;
}

@media (width >= 768px) {
  .signature-widget .dropdown-container {
    top: 0;
    right: 0;
  }
}

.dropdown-container {
  position: relative;
}

.dropdown-menu {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(103, 143, 46, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-drop-shadow: drop-shadow(0 1px 1px #0000000d);
  filter: var(--tw-filter);
  border-radius: .375rem;
  flex-direction: column;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
  position: absolute;
  top: 2.75rem;
  right: 0;
}

.dropdown-menu a, .dropdown-menu .dropdown-item {
  z-index: 30;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: .75rem 1.5rem;
}

@media (width >= 1024px) {
  .dropdown-menu a, .dropdown-menu .dropdown-item {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

.dropdown-menu a:hover {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(103, 143, 46, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dropdown-menu a.active {
  --tw-bg-opacity: 1;
  background-color: rgba(103, 143, 46, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.form-type {
  align-items: center;
  gap: .75rem;
  margin-bottom: .25rem;
  display: flex;
  position: relative;
}

.form-type:hover {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgba(241, 242, 242, var(--tw-bg-opacity));
}

.form-type .form-abbr {
  flex-shrink: 0;
  width: 3rem;
  height: 3rem;
  font-size: .75rem;
  line-height: 1rem;
}

.form-type .form-abbr:hover {
  cursor: pointer;
}

.form-type .form-checkbox {
  position: absolute;
  left: 1.25rem;
}

.form-type .form-checkbox:hover {
  cursor: pointer;
}

.form-type .form-arrows {
  flex-direction: column;
  gap: .5rem;
  display: flex;
  position: absolute;
  left: 1rem;
}

.form-type .form-arrows:hover {
  cursor: pointer;
}

.form-type .form-label {
  text-align: left;
  flex-grow: 1;
  justify-content: flex-start;
  padding: .5rem .5rem .5rem 3rem;
}

.form-type .form-label:hover {
  cursor: pointer;
}

.form-type .arrow-up-icon, .form-type .arrow-down-icon, .form-type .arrow-up-icon-first, .form-type .arrow-down-icon-last {
  background-position: center;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.25rem;
  display: block;
}

.form-type .arrow-up-icon {
  background-image: url("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 20 20\" fill=\"currentColor\" class=\"w-5 h-5\"><path fill-rule=\"evenodd\" d=\"M10 17a.75.75 0 01-.75-.75V5.612L5.29 9.77a.75.75 0 01-1.08-1.04l5.25-5.5a.75.75 0 011.08 0l5.25 5.5a.75.75 0 11-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0110 17z\" clip-rule=\"evenodd\" /></svg>");
}

.form-type .arrow-down-icon {
  background-image: url("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 20 20\" fill=\"currentColor\" class=\"w-5 h-5\"><path fill-rule=\"evenodd\" d=\"M10 3a.75.75 0 01.75.75v10.638l3.96-4.158a.75.75 0 111.08 1.04l-5.25 5.5a.75.75 0 01-1.08 0l-5.25-5.5a.75.75 0 111.08-1.04l3.96 4.158V3.75A.75.75 0 0110 3z\" clip-rule=\"evenodd\" /></svg>");
}

.form-type.moved-down {
  animation: .3s form-type-moved-down;
}

.form-type.moved-up-0 {
  animation: .3s form-type-moved-up;
}

.form-type.moved-up-1 {
  animation: .3s form-type-moved-up-1;
}

.form-type.moved-down:nth-of-type(3) .arrow-up-icon {
  animation: .3s opacity-fade-in;
}

.form-type.moved-up-0.moved-up-1:nth-of-type(2) .arrow-up-icon {
  animation: .3s opacity-fade-out;
}

.form-type:is(.moved-up-0, .moved-up-1):nth-last-of-type(2) .arrow-down-icon, .form-type.moved-down:last-of-type .arrow-down-icon {
  animation: .3s opacity-fade-in;
}

.form-picker-select-all {
  margin-bottom: .75rem;
  padding-left: 1.25rem;
}

.form-picker-select-all .select-all-label {
  margin-left: .75rem;
  margin-right: .75rem;
}

.form-picker-re-order.button, .form-picker-re-order-save.button {
  z-index: 10;
  align-items: center;
  gap: .25rem;
  margin: .25rem 1.5rem .25rem auto;
  padding: .25rem .75rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
  position: sticky;
  top: 4.7rem;
}

.form-picker-re-order.button .re-order-icon, .form-picker-re-order-save.button .re-order-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-6 h-6'%3E%3Cpath fill-rule='evenodd' d='M6.97 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06L8.25 4.81V16.5a.75.75 0 01-1.5 0V4.81L3.53 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zm9.53 4.28a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V7.5a.75.75 0 01.75-.75z' clip-rule='evenodd' /%3E%3C/svg%3E");
  width: 1rem;
  height: 1rem;
  display: block;
}

@keyframes form-type-moved-down {
  0% {
    position: relative;
    top: -4.25rem;
  }

  100% {
    position: relative;
    top: 0;
  }
}

@keyframes form-type-moved-up {
  0% {
    position: relative;
    top: 4.25rem;
  }

  100% {
    position: relative;
    top: 0;
  }
}

@keyframes opacity-fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes opacity-fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes form-type-moved-up-1 {
  0% {
    position: relative;
    top: 4.45rem;
  }

  100% {
    position: relative;
    top: 0;
  }
}

.expectation-picker.modal-grid {
  align-self: flex-start;
  margin-top: 6rem;
}

.expectation-picker .tabs, .expectation-picker .expectation-picker-body, .expectation-picker .expectation-picker-buttons {
  grid-column: span 2 / span 2;
  padding-left: .75rem;
  padding-right: .75rem;
}

@media (width >= 768px) {
  .expectation-picker .tabs, .expectation-picker .expectation-picker-body, .expectation-picker .expectation-picker-buttons {
    grid-column: 3 / span 8;
  }
}

@media (width >= 1024px) {
  .expectation-picker .tabs, .expectation-picker .expectation-picker-body, .expectation-picker .expectation-picker-buttons {
    grid-column: 4 / span 6;
  }
}

.expectation-picker .expectation-category > div[class=""], .expectation-picker .expectation-list > div[class=""] {
  gap: .5rem;
  line-height: 1.25rem;
  display: flex;
}

.expectation-picker .expectation-category > div[class=""] input[type="checkbox"], .expectation-picker .expectation-list > div[class=""] input[type="checkbox"] {
  flex-shrink: 0;
  margin-top: .15rem;
}

.expectation-picker .expectation-category > div[class=""] label, .expectation-picker .expectation-list > div[class=""] label {
  margin-left: 0;
  margin-right: 0;
}

.expectation-picker nav.tabs {
  grid-row-start: 1;
  gap: 1.5rem;
  display: flex;
}

.expectation-picker nav.tabs .active {
  font-weight: 600;
}

.expectation-picker .expectation-picker-body {
  grid-row-start: 2;
}

.expectation-picker .expectation-picker-body .widget-select-container, .expectation-picker .expectation-list div {
  margin-bottom: .75rem;
}

.expectation-picker .expectation-list h1 {
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: 2rem;
}

.expectation-picker .expectation-list h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.hidden-select-edit-button {
  --tw-text-opacity: 1;
  color: rgba(64, 89, 29, var(--tw-text-opacity));
  margin-right: .5rem;
  font-size: .75rem;
  line-height: 1rem;
}

.loading-spinner {
  text-indent: -9999px;
  white-space: nowrap;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='30' cy='50' fill='%233f403d' r='20'%3E%3Canimate attributeName='cx' repeatCount='indefinite' dur='1s' keyTimes='0;0.5;1' values='30;70;30' begin='-0.5s'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='70' cy='50' fill='%2394d137' r='20'%3E%3Canimate attributeName='cx' repeatCount='indefinite' dur='1s' keyTimes='0;0.5;1' values='30;70;30' begin='0s'%3E%3C/animate%3E%3C/circle%3E%3Ccircle cx='30' cy='50' fill='%233f403d' r='20'%3E%3Canimate attributeName='cx' repeatCount='indefinite' dur='1s' keyTimes='0;0.5;1' values='30;70;30' begin='-0.5s'%3E%3C/animate%3E%3Canimate attributeName='fill-opacity' values='0;0;1;1' calcMode='discrete' keyTimes='0;0.499;0.5;1' dur='1s' repeatCount='indefinite'%3E%3C/animate%3E%3C/circle%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  flex-grow: 1;
  grid-column: span 9 / span 9;
  align-self: center;
  min-height: 3rem;
  max-height: 12rem;
  margin-top: 1.25rem;
  display: flex;
  overflow: hidden;
}

.step-3 .loading-spinner {
  grid-column: span 9 / span 9;
}

.step-3-right .fields-wrapper .loading-spinner {
  grid-column: span 12 / span 12;
}

.loading-overlay {
  z-index: 42;
  background-color: #00000045;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loading-overlay .loading-spinner {
  opacity: 1;
  align-self: center;
}

.pager {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.pager .btn {
  --tw-bg-opacity: 0;
  margin: .125rem;
  padding-left: .75rem;
  padding-right: .75rem;
}

.pager .btn:hover {
  --tw-bg-opacity: .4;
}

.pager .btn.btn-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.teacher-semester-selections {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(241, 242, 242, var(--tw-bg-opacity));
  grid-column: span 2 / span 2;
  padding: .5rem 1.25rem .25rem;
}

@media (width >= 768px) {
  .teacher-semester-selections {
    grid-column: span 9 / span 9;
  }
}

.teacher-semester-selections .widget-select-container {
  display: block;
}

@media (width >= 1024px) {
  .teacher-semester-selections .widget-select-container {
    margin-left: .25rem;
    margin-right: .25rem;
    display: inline-block;
  }
}

.teacher-semester-selections .widget-select {
  min-width: 20rem;
}

.teacher-semester-selections p {
  margin-right: .25rem;
}

@media (width >= 1024px) {
  .teacher-semester-selections p {
    padding-top: .75rem;
    padding-bottom: .75rem;
    display: inline-block;
  }
}

.teacher-semester-selections .hidden-select, .teacher-semester-selections .hidden-select p {
  display: inline-block;
}

.teacher-semester-selections .hidden-select p {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-style: italic;
}

.step-3 .teacher-semester-selections {
  background-color: #0000;
  border-bottom-width: 0;
  padding: 0;
}

.signatures-heading {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.signature-group {
  flex-wrap: wrap;
  flex-grow: 1;
  gap: 1.25rem;
  padding: .75rem;
  display: flex;
}

.signature-widget {
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 15rem;
  max-width: 100%;
  padding-bottom: .75rem;
  position: relative;
}

@media (width >= 640px) {
  .signature-widget {
    min-width: 40%;
  }
}

@media (width >= 768px) {
  .signature-widget {
    max-width: 33.33%;
  }
}

.btn-group-email {
  width: 100%;
}

.signature-type {
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

.signature-copy-link, .signature-add-email {
  --tw-text-opacity: 1;
  color: rgba(103, 143, 46, var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

.signature-name, .signature-email {
  font-size: .875rem;
  line-height: 1.25rem;
}

.sign-here {
  --tw-border-opacity: 1;
  border-style: dashed;
  border-bottom-width: 2px;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgba(63, 64, 61, var(--tw-text-opacity));
  --tw-text-opacity: .9;
  width: 100%;
  height: 8rem;
  min-height: 5rem;
  margin-bottom: .75rem;
}

.sign-here.emailed {
  display: flex;
}

.sign-here > img {
  max-height: 8rem;
}

.emailed-notice {
  text-align: center;
  align-self: center;
  width: 100%;
}

.widget-sub-form > .label-section {
  margin-bottom: .75rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.comment-section-heading {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.comment-section {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.comment {
  border-bottom: 1px solid #aaa;
  flex-direction: column;
  row-gap: .5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.comment-head {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.comment-head .comment-poster, .comment-head .comment-times, .comment-head .comment-is-public {
  font-size: .875rem;
  line-height: 1.25rem;
}

.comment-head .comment-times, .comment-head .comment-is-public {
  margin-left: .5rem;
}

.comment-head .comment-times:before, .comment-head .comment-is-public:before {
  content: "—";
  margin-right: .5rem;
}

@media (width <= 500px) {
  .comment-head .comment-times:before, .comment-head .comment-is-public:before {
    display: none;
  }
}

.comment-head .dropdown-container {
  margin-left: auto;
}

.comment-head .dropdown-container .dropdown-menu {
  z-index: 100;
}

.comment-form {
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
  display: flex;
}

.comment-form-header {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.comment-form > textarea {
  appearance: none;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  --tw-border-opacity: .5;
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
  outline-offset: 2px;
  border-radius: .375rem;
  outline: 2px solid #0000;
  align-self: stretch;
}

.comment-form > textarea:focus {
  --tw-border-opacity: 1;
  border-color: rgba(142, 198, 63, var(--tw-border-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(142, 198, 63, var(--tw-ring-opacity));
  --tw-ring-opacity: .5;
}

.comment-buttons {
  flex-direction: rw;
  display: flex;
}

.comment-buttons > :first-child {
  margin-left: 0;
}

.attachment-section-heading {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.attachment-section, .attachment-list {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.attachment {
  border-bottom: 1px solid #aaa;
  flex-direction: row;
  place-items: center flex-start;
  column-gap: .5rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: flex;
}

.attachment-label {
  overflow-wrap: anywhere;
  --tw-text-opacity: 1;
  color: rgba(64, 89, 29, var(--tw-text-opacity));
  margin-right: auto;
}

.attachment-label:hover {
  --tw-text-opacity: 1;
  color: rgba(103, 143, 46, var(--tw-text-opacity));
  text-decoration: underline;
}

.attachment-meta > div {
  white-space: nowrap;
  font-size: .875rem;
  line-height: 1.25rem;
}

.attachment-head {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.attachment-form {
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
  display: flex;
}

.attachment-form-header {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.attachment-form > input {
  align-self: stretch;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.attachment-buttons {
  flex-direction: rw;
  justify-self: flex-end;
  display: flex;
}

.attachment-buttons > :first-child {
  margin-left: 0;
}

.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip .tip-content {
  --tw-bg-opacity: 1;
  background-color: rgba(63, 64, 61, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(241, 242, 242, var(--tw-text-opacity));
  visibility: hidden;
  text-align: center;
  z-index: 1;
  width: 120px;
  margin-left: -60px;
  padding: .5rem;
  position: absolute;
  bottom: 100%;
  left: 50%;
}

.tooltip:hover .tip-content {
  visibility: visible;
}

.sig-request-review-form .modal-heading, .sig-request-resolve-form .modal-heading {
  font-size: 1.35rem;
  font-weight: 400;
}

.sig-request-review-form {
  flex-direction: column;
  display: flex;
}

.sig-request-review-form .image-replace {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
}

.sig-request-review-form .image-replace.no-saved-email {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-6 h-6'%3e%3cpath fill-rule='evenodd' d='M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z' clip-rule='evenodd'/%3e%3c/svg%3e");
}

.sig-request-review-form .image-replace.email-already-sent {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-6 h-6'%3e%3cpath d='M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z'/%3e%3cpath d='M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z'/%3e%3c/svg%3e");
}

.sig-request-review-form .image-replace.edit-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgb(64, 89, 29)' class='w-6 h-6'%3e%3cpath d='M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z'/%3e%3c/svg%3e");
  position: relative;
  top: -2px;
}

.sig-request-group {
  flex-direction: row;
  margin-bottom: 1.5rem;
  display: flex;
}

.sig-request-group-header > .form-abbr {
  flex-shrink: 0;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: .75rem;
  position: sticky;
  top: 1rem;
}

.sig-request-group-select {
  flex-direction: column;
  display: flex;
}

.sig-request-subgroup > summary {
  text-transform: uppercase;
  margin-top: 1rem;
  font-size: .9rem;
}

.sig-request-subgroup > span {
  flex-direction: row;
  display: flex;
}

.sig-review-check, .sig-request-resolve-option {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.sig-review-check > label, .sig-request-resolve-option > label {
  flex-direction: row;
  align-items: center;
  margin-left: .5rem;
  display: flex;
}

.edit-btn {
  flex-direction: row;
  display: flex;
}

.edit-btn-text {
  --tw-text-opacity: 1;
  color: rgba(64, 89, 29, var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

.sig-request-table thead td {
  font-weight: 500;
}

.sig-request-table td {
  padding-right: 1rem;
}

.signature-req-edit-email > .modal-heading {
  margin-bottom: -1rem;
}

.signature-employer-container {
  flex-direction: column;
  grid-column: span 2 / span 2;
  grid-row-start: 2;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

@media (width >= 768px) {
  .signature-employer-container {
    grid-column: 4 / span 6;
  }
}

@media (width >= 1024px) {
  .signature-employer-container {
    grid-column: 5 / span 4;
  }
}

.req-email-address {
  grid-column: span 2 / span 2;
  grid-row-start: 3;
}

@media (width >= 768px) {
  .req-email-address {
    grid-column: 4 / span 6;
  }
}

@media (width >= 1024px) {
  .req-email-address {
    grid-column: 5 / span 4;
  }
}



/*# sourceMappingURL=main.css.map */
