.page-heading {
  @apply
    text-2xl
    font-normal
    flex-grow
    flex-shrink-0;

  line-height: 4rem;
  z-index: 100;

}

.widget-heading {
  @apply text-xl font-medium mb-2;
}

.page-heading + .page-subheading {
  @apply
    text-xl
    font-extralight
    leading-normal;

  flex-basis: 100%;
}
