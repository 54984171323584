.step-3 .page-header .btn-group {
  @apply
    py-1
    lg:py-0
    gap-1
    lg:gap-3
    flex-wrap;

  .btn {
    @apply
      flex-grow
      sm:leading-4
      lg:leading-snug;
  }
}

.step-3-right.form-submission {
  @apply
    col-span-2
    md:col-span-9
    mb-20;
  
  textarea {
    min-height: 8rem;
  }
}

.step-3-h-full-textarea {
  textarea {
    @apply
      h-full;
  }
}

.form-updated-times {
  @apply
    mt-3
    text-sm;
}

.step-3 .page-header {
  @apply
    md:flex-nowrap;
}

.step-3 .page-headings {
  @apply
    flex
    flex-grow
    align-baseline
    flex-wrap;
}

.step-3 .page-subheading {
    @apply
        whitespace-nowrap;
}

.step-3 .page-heading {
  @apply
    /**
     * Step 3 has multiple headings and lots of buttons, so needs
     * reduced leading on mobile.
     */
    leading-normal

    /**
     * Header has a fixed height so we can set `top` on workqueue, this
     * prevents long student names overflowing the header.
     * Note: workqueue isn't sticky on mobile, so this isn't relevant there.
     */
    md:max-h-8
    md:overflow-hidden
    lg:max-h-full;
}

.step-3-form {
  @apply
    grid
    grid-cols-12
    gap-3
    pb-10;
}

.step-3-right .widget-select-container {
  @apply
    flex-grow;
}

/* List widget, lists of Expectations in form submissions for example. */

.step-3-form .list {
  .list-entry {
    @apply
      p-3
      flex
      flex-col
      gap-3;

    summary {
      @apply
        mb-3
    }
  }

  .list-entry:nth-child(odd) {
    @apply
      bg-gray-50;
  }

  .btn {
    @apply
      self-end;
  }
}

.search-subform-select-wrapper {
  @apply
    flex
    flex-wrap
    gap-x-3;

  label {
    @apply
      w-full;
  }
}

/* Step 3 scroll offset, to account for static .page-header. */
.step-3-scroll {
  scroll-padding-top: 5rem;
  width: 100%;
}
