/**
 * @file
 * General modal + specific modal type styling .
 */

.modal {
  justify-content: center;
  @apply
    bg-gray-100
    fixed
    top-0 right-0
    w-screen
    h-screen
    overflow-y-auto
    z-[9999]
    flex;

    .modal-close {
      @apply
        cursor-pointer
        block
        overflow-hidden
        absolute
        w-10 h-10
        bg-center
        bg-no-repeat
        top-3
        right-3
        md:top-5
        md:right-5;
      text-indent: -9999px;
      white-space: nowrap;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z' fill='%2360625dff'%3E%3C/path%3E%3C/svg%3E");
    }

  .modal-heading {
    @apply
      text-xl
      font-extralight;
  }
}


/* Sane modal */

.modal-col {
    @apply mt-2;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    max-width: 1000px;

    &>.modal-heading {
        margin-bottom: 2rem;
    }

    &>button, &>.modal-buttons {
        align-self: end;
    }

    .modal-heading {
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

.modal-col.modal-narrow {
    max-width: 600px;
}

.modal-col.modal-wide {
    max-width: 1200px;
}

/* Modal that is laid out using CSS grid. */

.modal-grid {
  @apply
    flex-grow
    grid
    grid-cols-2
    md:grid-cols-12
    gap-3
    gap-y-6
    items-center
    justify-center
    self-center
    py-3;

  .modal-heading,
  .modal-body,
  .modal-buttons {
    @apply
      col-span-2
      md:col-start-4
      lg:col-start-5
      md:col-span-6
      lg:col-span-4
      px-3
      md:px-0;
  }

  .modal-heading {
    @apply
      leading-none
      row-start-1;
  }

  .modal-body {
    @apply
      row-start-2;
  }

  .modal-buttons {
    @apply
      row-start-3
      justify-end;
  }

}

.modal-buttons {
  @apply
    flex
    gap-3;
  
  .btn {
    @apply
      flex-grow
      lg:flex-grow-0
      flex-1
      lg:flex-auto
      m-0;
  }
}
