
.workqueue {
  @apply
    mb-3
    md:mb-0
    col-span-2
    md:col-span-3
    md:sticky
    top-16
    self-start
    border-hr-background-light;

    @media (min-width: 768px) {
      /**
      * (Screen height) - (Page header line-height) - (Page header
      * border-bottom) - (HR header height).
      */
      max-height: calc(100vh - 4rem - 1px - 5rem);
      overflow-y: auto;
      overflow-x: hidden;
    }

  li {
    @apply
      list-none;
  }

  .expand-collapse-buttons {
    @apply
      text-xs
      flex
      gap-5
      text-gray-800
      pl-5;
  }

  a {
    @apply
      cursor-pointer;

    &:hover {
      @apply
        text-hr-logo-green-dark-1
        text-opacity-100;
    }
  }
}

/* Use 'forms-spa-standalone' to remove HR header height from
   height calculations. */
.forms-spa-standalone .workqueue {
  @media (min-width: 768px) {
    /**
    * (Screen height) - (Page header line-height) - (Page header
    * border-bottom).
    */
    max-height: calc(100vh - 4rem - 1px);
    overflow-y: auto;
  }
}

.workqueue-students {
  @apply
    mb-3
    lg:mb-5;
}

.workqueue-students:empty {
  @apply
    text-gray-400
    text-center
    text-sm
    self-center
    mt-5
    hidden
    md:block;

  &::before {
    /* @todo: this icon is very meh, find something nicer. */
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve'%3E%3Cstyle%3E%0Apath %7B fill: rgba(241, 242, 242, 0.5);%0A%7D%0A%3C/style%3E%3Cg%3E%3Cpath d='M165,0C74.019,0,0,74.02,0,165.001C0,255.982,74.019,330,165,330s165-74.018,165-164.999C330,74.02,255.981,0,165,0z M165,300c-74.44,0-135-60.56-135-134.999C30,90.562,90.56,30,165,30s135,60.562,135,135.001C300,239.44,239.439,300,165,300z'/%3E%3Cpath d='M164.998,70c-11.026,0-19.996,8.976-19.996,20.009c0,11.023,8.97,19.991,19.996,19.991 c11.026,0,19.996-8.968,19.996-19.991C184.994,78.976,176.024,70,164.998,70z'/%3E%3Cpath d='M165,140c-8.284,0-15,6.716-15,15v90c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15v-90C180,146.716,173.284,140,165,140z '/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    display: block;
    width: 100%;
    min-height: 3rem;
    max-height: 12rem;
    overflow: hidden;
    height: 8rem;
    content: "";
  }

  &::after {
    position: relative;
    top: 1rem;
    white-space: pre;
    /* @todo: move this into the application so it may be translated. */
    content: "No students or forms have \A been selected yet";
  }
}

.workqueue-student {

  @apply
    border-b
    flex
    items-center
    justify-start;

  &.no-forms {
    @apply
      pl-5
      py-3;
  }

  summary:hover {
      @apply
        text-hr-logo-green-dark-1;
  }

  .summary-student {
    @apply
      pl-1
      lg:pl-2
      pr-10
      pt-3
      pb-3
      relative;
    left: .12rem;
  }

  /* When the details are open and there are child items, there shouldn't
      be a big gap between the summary and those items. */
  > details[open] .summary-student {
    @apply
      pb-1;
  }

  .workqueue-student-forms {

    li {
      @apply
        text-sm
        ml-8
        text-opacity-75;
    }

    .student-form-section {
      @apply
        mb-3;
    }

    .workqueue-form-no-sections a,
    .student-form-section a {
      @apply
        px-2
        py-1
        inline-flex;
    }

    .summary-form a {
      @apply
        py-2
        /* Halt! If you're thinking of changing this to `inline-flex` so the
           behaviour of links in the workqueue is consistent, maybe reconsider.
           Unless we now have alternate styling for the summary tag's arrow,
           Chrome will bump the <a> down and it won't be on the same line as
           the arrow. See:
           https://css-tricks.com/two-issues-styling-the-details-element-and-how-to-solve-them/#aa-issue-2-nested-block-elements-in-summary
        */
        inline;
      width: calc(100% - .7rem);
    }

    /* When the details are open and there are child items, there shouldn't
       be a big gap between the summary and those items. */
    details[open] .summary-form a {
      @apply
        pb-1;
    }
  }

}

.workqueue-selected {
  @apply
    font-bold
}

/**
 * Handle corner case of when the form design is unknown, or has no sections.
 * This mostly happens on Step2, when a form design is selected the JSON
 * for which has yet to be downloaded.
 */
.workqueue-form-no-sections a {
  @apply
    cursor-auto;
  &:hover {
    @apply
      text-current;
  }
}
.workqueue-form-no-sections:last-child {
  @apply
    mb-2;
}
