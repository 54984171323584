.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tip-content {
    @apply text-hr-background-light bg-hr-background-dark;

    visibility: hidden;

    text-align: center;
    width: 8rem;
    padding: 0.5rem;

    width: 120px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;

    position: absolute;
    z-index: 1;
}

.tooltip:hover .tip-content {
    visibility: visible;
}
